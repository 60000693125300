var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _vm.withCamera
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["monitor_realvideo_button"],
                      expression: "['monitor_realvideo_button']",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: _vm.isGettingVehicleInfo || _vm.isTrackTab,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openModal("showCameras")
                    },
                  },
                },
                [_vm._v(" 实时视频 ")]
              )
            : _vm._e(),
          _vm.withCamera
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["monitor_historyvideo_button"],
                      expression: "['monitor_historyvideo_button']",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: _vm.isGettingVehicleInfo || _vm.isTrackTab,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openModal("showCameraHistory")
                    },
                  },
                },
                [_vm._v(" 视频回放 ")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["monitor_task_button"],
                  expression: "['monitor_task_button']",
                },
              ],
              attrs: {
                type: "primary",
                size: "small",
                disabled: _vm.isGettingVehicleInfo || _vm.isTrackTab,
              },
              on: {
                click: function ($event) {
                  return _vm.openModal("handleViewTask")
                },
              },
            },
            [_vm._v(" 任务管理 ")]
          ),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["monitor_control_button"],
                  expression: "['monitor_control_button']",
                },
              ],
              attrs: {
                type: "primary",
                size: "small",
                disabled:
                  _vm.isGettingVehicleInfo || _vm.isTrackTab || _vm.ctrling,
              },
              on: {
                click: function ($event) {
                  return _vm.openModal("showModalCtrl")
                },
              },
            },
            [_vm._v(" 遥控车辆 ")]
          ),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["monitor_monitor_button"],
                  expression: "['monitor_monitor_button']",
                },
              ],
              attrs: {
                type: "primary",
                size: "small",
                disabled: _vm.isGettingVehicleInfo || _vm.isTrackTab,
              },
              on: {
                click: function ($event) {
                  return _vm.openModal("handleMonitor")
                },
              },
            },
            [_vm._v(" 监控 ")]
          ),
          _vm.isSupportRtc
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["monitor_remote_screen_button"],
                      expression: "['monitor_remote_screen_button']",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled:
                      _vm.isGettingVehicleInfo || _vm.isTrackTab || _vm.ctrling,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openScreen()
                    },
                  },
                },
                [_vm._v(" 低延时投屏 ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.cameraVisible
        ? _c("monitor-main-camera-modal", {
            attrs: {
              info: _vm.objectInfo,
              videoType: _vm.videoType,
              rtcInfo: _vm.rtcInfo,
              direction: "front",
              position: { top: "1%", left: "80%" },
            },
            on: { closeModalCamera: _vm.closeModalCamera },
          })
        : _vm._e(),
      _vm.cameraHistoryVisible
        ? _c("monitor-camera-history-modal", {
            attrs: {
              info: _vm.objectInfo,
              "camera-history-visible": _vm.cameraHistoryVisible,
              position: { top: "0%", left: "30%" },
            },
            on: { closeModalCameraHistory: _vm.closeModalCameraHistory },
          })
        : _vm._e(),
      _vm.ctrlVisible
        ? _c("monitor-vehicle-ctrl", {
            attrs: {
              info: _vm.objectInfo,
              rtcInfo: _vm.rtcInfo,
              "ctrl-visible": _vm.ctrlVisible,
              videoType: _vm.videoType,
              showLeftModal: _vm.showLeftModal,
              formScreen: _vm.formScreen,
              position: {
                top: "0%",
                left: _vm.modalParam.left + _vm.modalParam.width + 10,
              },
              vehicleLoginInfo: _vm.vehicleLoginInfo,
              brtc: _vm.brtc,
            },
            on: {
              closeModalCtrl: _vm.closeModalCtrl,
              targeCtrlType: _vm.targeCtrlType,
            },
          })
        : _vm._e(),
      _vm.ctrlScreenVisible
        ? _c("monitor-vehicle-ctrl-screen", {
            attrs: {
              info: _vm.objectInfo,
              rtcInfo: _vm.rtcInfo,
              "ctrl-visible": _vm.ctrlScreenVisible,
              videoType: _vm.videoType,
              showLeftModal: _vm.showLeftModal,
              position: {
                top: "0%",
                left: _vm.modalParam.left + _vm.modalParam.width + 10,
              },
              vehicleLoginInfo: _vm.vehicleLoginInfo,
              brtc: _vm.brtc,
            },
            on: {
              closeModalCtrl: _vm.closeModalCtrl,
              targeCtrlType: _vm.targeCtrlType,
            },
          })
        : _vm._e(),
      _vm.viewTaskVisible
        ? _c("view-task-container-modal", {
            attrs: {
              info: _vm.objectInfo,
              position: {
                top: "0%",
                left: _vm.modalParam.left + _vm.modalParam.width + 10,
              },
              isShowModal: _vm.viewTaskVisible,
              vehicleId: _vm.objectInfo.vehicleId,
            },
            on: {
              closeViewTaskModal: _vm.handleCloseViewTaskModal,
              viewRoute: _vm.handleViewRoute,
            },
          })
        : _vm._e(),
      _vm.manyVehicleMonitorVisible
        ? _c("many-vehicle-monitor", {
            ref: "manyVehicleMonitorRef",
            attrs: {
              objectPoints: _vm.manyMonitorObjects,
              taskStatusOptions: _vm.taskStatusOptions,
              manyVehicleMonitorVisible: _vm.manyVehicleMonitorVisible,
            },
            on: {
              onCloseManyVehicleMonitor: _vm.handleCloseManyVehicleMonitor,
              onVehicleMonitorEvent: _vm.handleVehicleMonitorEvent,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }