<template>
  <div>
    <div class="container">
      <a-button
        type="primary"
        size="small"
        :disabled="isGettingVehicleInfo || isTrackTab"
        @click="openModal('showCameras')"
        v-if="withCamera"
        v-hasPermi="['monitor_realvideo_button']"
      >
        实时视频
      </a-button>
      <a-button
        type="primary"
        size="small"
        :disabled="isGettingVehicleInfo || isTrackTab"
        @click="openModal('showCameraHistory')"
        v-if="withCamera"
        v-hasPermi="['monitor_historyvideo_button']"
      >
        视频回放
      </a-button>
      <a-button
        type="primary"
        size="small"
        :disabled="isGettingVehicleInfo || isTrackTab"
        @click="openModal('handleViewTask')"
        v-hasPermi="['monitor_task_button']"
      >
        任务管理
      </a-button>
      <a-button
        type="primary"
        size="small"
        :disabled="isGettingVehicleInfo || isTrackTab || ctrling"
        @click="openModal('showModalCtrl')"
        v-hasPermi="['monitor_control_button']"
      >
        遥控车辆
      </a-button>
      <a-button
        type="primary"
        size="small"
        :disabled="isGettingVehicleInfo || isTrackTab"
        @click="openModal('handleMonitor')"
        v-hasPermi="['monitor_monitor_button']"
      >
        监控
      </a-button>
      <a-button
        type="primary"
        size="small"
        v-if="isSupportRtc"
        :disabled="isGettingVehicleInfo || isTrackTab || ctrling"
        @click="openScreen()"
        v-hasPermi="['monitor_remote_screen_button']"
      >
        低延时投屏
      </a-button>
    </div>
    <monitor-main-camera-modal
      :info="objectInfo"
      :videoType="videoType"
      :rtcInfo="rtcInfo"
      direction="front"
      v-if="cameraVisible"
      @closeModalCamera="closeModalCamera"
      :position="{ top: '1%', left: '80%' }"
    />
    <monitor-camera-history-modal
      :info="objectInfo"
      v-if="cameraHistoryVisible"
      :camera-history-visible="cameraHistoryVisible"
      @closeModalCameraHistory="closeModalCameraHistory"
      :position="{ top: '0%', left: '30%' }"
    />
    <monitor-vehicle-ctrl
      :info="objectInfo"
      :rtcInfo="rtcInfo"
      v-if="ctrlVisible"
      :ctrl-visible="ctrlVisible"
      :videoType="videoType"
      :showLeftModal="showLeftModal"
      :formScreen="formScreen"
      @closeModalCtrl="closeModalCtrl"
      @targeCtrlType="targeCtrlType"
      :position="{ top: '0%', left: modalParam.left + modalParam.width + 10 }"
      :vehicleLoginInfo="vehicleLoginInfo"
      :brtc="brtc"
    />
    <monitor-vehicle-ctrl-screen
      :info="objectInfo"
      :rtcInfo="rtcInfo"
      v-if="ctrlScreenVisible"
      :ctrl-visible="ctrlScreenVisible"
      :videoType="videoType"
      :showLeftModal="showLeftModal"
      @closeModalCtrl="closeModalCtrl"
      @targeCtrlType="targeCtrlType"
      :position="{ top: '0%', left: modalParam.left + modalParam.width + 10 }"
      :vehicleLoginInfo="vehicleLoginInfo"
      :brtc="brtc"
    />
    <view-task-container-modal
      :info="objectInfo"
      v-if="viewTaskVisible"
      @closeViewTaskModal="handleCloseViewTaskModal"
      :position="{ top: '0%', left: modalParam.left + modalParam.width + 10 }"
      :isShowModal="viewTaskVisible"
      :vehicleId="objectInfo.vehicleId"
      @viewRoute="handleViewRoute"
    />
    <!-- 记得加上v-if,否则不会关闭窗体不会释放资源 -->
    <many-vehicle-monitor
      ref="manyVehicleMonitorRef"
      :objectPoints="manyMonitorObjects"
      :taskStatusOptions="taskStatusOptions"
      v-if="manyVehicleMonitorVisible"
      :manyVehicleMonitorVisible="manyVehicleMonitorVisible"
      @onCloseManyVehicleMonitor="handleCloseManyVehicleMonitor"
      @onVehicleMonitorEvent="handleVehicleMonitorEvent"
    />
  </div>
</template>
<script>
import MonitorMainCameraModal from './modules/MonitorMainCameraModal'
import MonitorCameraHistoryModal from './modules/MonitorCameraHistoryModal'
import MonitorVehicleCtrl from './modules/MonitorVehicleCtrl'
import MonitorVehicleCtrlScreen from './modules/MonitorVehicleCtrl_Screen'
import ViewTaskContainerModal from './modules/VehicleTask/ViewTaskContainerModal'
import ManyVehicleMonitor from './modules/ManyVehicleMonitor'
import { getLanes } from '@/api/iot/routePlanning'
import { vehicleLogin, webReturnDeviceVehicleId, webApplyDevice } from '@/api/iot/cameraDevice'
import { InitDelayStatis, AddStartRecord, getTimestampAndSeq } from '@/utils/delayTest'

export default {
  name: 'VehicleFuncList',
  props: {
    objectInfo: {
      type: Object,
      default: () => {}
    },
    objectPoints: {
      type: Array,
      default: () => {}
    },
    currentCheckedList: {
      type: Array,
      default: () => {}
    },
    taskStatusOptions: {
      type: Array,
      default: () => {}
    },
    modalParam: {
      type: Object,
      default: () => {}
    },
    currentSelectedId: {
      type: String,
      default: () => {}
    },
    isGettingVehicleInfo: {
      type: Boolean,
      default: false
    },
    // 是否正在播放轨迹
    isTrackTab: {
      type: Boolean,
      default: false
    },
    showLeftModal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MonitorMainCameraModal,
    MonitorCameraHistoryModal,
    MonitorVehicleCtrl,
    ViewTaskContainerModal,
    ManyVehicleMonitor,
    MonitorVehicleCtrlScreen
  },
  data() {
    return {
      cameraVisible: false,
      cameraHistoryVisible: false,
      ctrlVisible: false,
      ctrlScreenVisible: false,
      viewTaskVisible: false,
      sendTaskVisible: false,
      manyVehicleMonitorVisible: false,
      vehicleTaskContainerLeft: 0,
      // 有摄像头的车辆
      vehicleListWithCamera: [],
      withCamera: false,
      videoType: 'rtmp',
      rtcInfo: {},
      // 多车监控车辆列表
      manyMonitorObjects: [],
      // 遥控登录信息
      vehicleLoginInfo: {},
      brtc: null,
      isSupportRtc: false,
      ctrling: false,
      formScreen: false
    }
  },
  mounted() {
    const screening = localStorage.getItem('screening')
    if (screening === 'true') {
      this.ctrling = true
      setTimeout(() => {
        this.checkScreening()
      }, 1000)
    }
  },
  watch: {
    objectInfo(newVal, oldVal) {
      if (newVal) {
        if (newVal.rtc || newVal.brtc) {
          this.isSupportRtc = true
        } else {
          this.isSupportRtc = false
        }
        if (!newVal.cameras || newVal.cameras.length === 0) {
          if (newVal.rtc && newVal.rtc.vehicleCameraDeviceInfos.length > 0) {
            this.withCamera = true
          } else {
            this.withCamera = false
          }
        } else {
          this.withCamera = true
        }
      }
    },
    objectPoints(newVal, oldVal) {
      if (!newVal || newVal.length === 0) {
        this.manyVehicleMonitorVisible = false
      }
    },
    cameraVisible(newVal, oldVal) {
      console.log('cameraVisible *****', newVal)
      this.handleUpdateMap()
    },
    cameraHistoryVisible(newVal, oldVal) {
      this.handleUpdateMap()
    },
    ctrlVisible(newVal, oldVal) {
      this.handleUpdateMap()
    },
    viewTaskVisible(newVal, oldVal) {
      this.handleUpdateMap()
    },
    sendTaskVisible(newVal, oldVal) {
      this.handleUpdateMap()
    },
    manyVehicleMonitorVisible(newVal, oldVal) {
      this.handleUpdateMap()
    }
  },
  methods: {
    targeCtrlType(type) {
      // 1 正常遥控 2 切换低延时投屏
      console.log('targeCtrlType:', type)
      if (type === '1') {
        this.formScreen = true
        this.ctrlVisible = true
        this.ctrlScreenVisible = false
      } else if (type === '2') {
        this.ctrlVisible = false
        this.$nextTick(() => {
          this.ctrlScreenVisible = true
        })
      }
    },
    webApplyDevice() {
      return new Promise((resolve) => {
        webApplyDevice({ vehicleId: this.objectInfo.vehicleId, rtcType: 'brtc' }).then(async (res) => {
          console.log('webApplyDevice **** 1', res.data)
          resolve(res.data)
        })
      })
    },
    async openScreen() {
      // 打开新窗口
      const RtcCameraType = {
        FRONT_CAMERA: 'CAMERA_FRONT_LONG',
        LEFT_CAMERA: 'CAMERA_LEFT_FISH',
        RIGHT_CAMERA: 'CAMERA_RIGHT_FISH',
        BACK_CAMERA: 'CAMERA_BACK_WIDE'
      }
      if (this.objectInfo.brtc) {
        this.objectInfo.brtc.userTokens = await this.webApplyDevice()
        if (!this.objectInfo.brtc.userTokens || this.objectInfo.brtc.userTokens.length === 0) {
          this.$warningEx('brtc用户申请失败，无法进入投屏')
          return
        }
      }
      const info =
        this.objectInfo &&
        (this.objectInfo.brtc ? this.objectInfo.brtc : this.objectInfo.rtc ? this.objectInfo.rtc : null)
      const mainInfo = this.objectInfo.brtc
        ? this.objectInfo.brtc
        : this.getRtcDestDeviceInfo(RtcCameraType.FRONT_CAMERA)
      const leftInfo = this.objectInfo.brtc
        ? this.objectInfo.brtc
        : this.getRtcDestDeviceInfo(RtcCameraType.LEFT_CAMERA)
      const rightInfo = this.objectInfo.brtc
        ? this.objectInfo.brtc
        : this.getRtcDestDeviceInfo(RtcCameraType.RIGHT_CAMERA)
      const videoType = this.objectInfo.brtc ? 'brtc' : 'rtc'
      if (info) {
        console.log('info', window.location.origin + '/monitor/rtcScreen?type=main&info=')
        window.open(
          window.location.origin +
            '/monitor/rtcScreen?type=main&info=' +
            JSON.stringify(mainInfo) +
            '&vehicleId=' +
            this.objectInfo.vehicleId +
            '&videoType=' +
            videoType +
            '&brtcId=therevideo2'
        )
        window.open(
          window.location.origin +
            '/monitor/rtcScreen?type=left&info=' +
            JSON.stringify(leftInfo) +
            '&vehicleId=' +
            this.objectInfo.vehicleId +
            '&videoType=' +
            videoType +
            '&brtcId=therevideo'
        )
        window.open(
          window.location.origin +
            '/monitor/rtcScreen?type=right&info=' +
            JSON.stringify(rightInfo) +
            '&vehicleId=' +
            this.objectInfo.vehicleId +
            '&videoType=' +
            videoType +
            '&brtcId=therevideo3'
        )
        this.ctrling = true
        localStorage.setItem('screening', 'true')
        setTimeout(() => {
          this.checkScreening('click')
        }, 3000)
      }
    },
    checkScreening(type) {
      if (type === 'click') {
        localStorage.removeItem('RTCSCREEN-main')
        localStorage.removeItem('RTCSCREEN-left')
        localStorage.removeItem('RTCSCREEN-right')
        this.lastMainTime = null
        this.lastLeftTime = null
        this.lastRightTime = null
      } else {
        this.lastMainTime = localStorage.getItem('RTCSCREEN-main')
        this.lastLeftTime = localStorage.getItem('RTCSCREEN-left')
        this.lastRightTime = localStorage.getItem('RTCSCREEN-right')
      }
      let flag = false
      let i = 0
      let jf = false
      this.timer = setInterval(() => {
        if (this.lastMainTime && this.lastLeftTime && this.lastRightTime) {
          if (!flag) {
            setTimeout(() => {
              if (!jf) {
                this.checkEvent()
                flag = true
                jf = true
              }
            }, 1000)
          } else {
            this.checkEvent()
          }
        } else if (type !== 'click') {
          if (i > 2) {
            this.ctrling = false
            localStorage.setItem('screening', 'false')
            i = 0
            clearInterval(this.timer)
          }
          i++
        } else {
          this.lastMainTime = localStorage.getItem('RTCSCREEN-main')
          this.lastLeftTime = localStorage.getItem('RTCSCREEN-left')
          this.lastRightTime = localStorage.getItem('RTCSCREEN-right')
        }
      }, 1000)
    },
    checkEvent() {
      const mainTime = localStorage.getItem('RTCSCREEN-main')
      const leftTime = localStorage.getItem('RTCSCREEN-left')
      const rightTime = localStorage.getItem('RTCSCREEN-right')
      if (mainTime === this.lastMainTime && leftTime === this.lastLeftTime && rightTime === this.lastRightTime) {
        console.log('this.lastMainTime:', this.lastMainTime)
        console.log('mainTime:', mainTime)
        console.log('this.lastLeftTime:', this.lastLeftTime)
        console.log('leftTime:', leftTime)
        console.log('this.lastRightTime:', this.lastRightTime)
        console.log('rightTime:', rightTime)
        this.ctrling = false
        localStorage.setItem('screening', 'false')
        clearInterval(this.timer)
      }
      this.lastMainTime = mainTime
      this.lastLeftTime = leftTime
      this.lastRightTime = rightTime
    },
    // 获取对端摄像头
    getRtcDestDeviceInfo(cameraType) {
      if (this.objectInfo.rtc && this.objectInfo.rtc.vehicleCameraDeviceInfos) {
        const deviceList = this.objectInfo.rtc.vehicleCameraDeviceInfos
        for (let n = deviceList.length, k = 0; k < n; k++) {
          if (deviceList[k].cameraType === cameraType) {
            console.log('destCamera', deviceList[k])
            return deviceList[k]
          }
        }
      } else {
        return {}
      }
    },
    openModal(fn) {
      console.log('openModal')
      this.closeOtherModal()
      if (fn === 'showCameras') {
        this.showCameras()
      } else if (fn === 'showCameraHistory') {
        this.showCameraHistory()
      } else if (fn === 'handleViewTask') {
        this.handleViewTask()
      } else if (fn === 'showModalCtrl') {
        this.showModalCtrl()
      } else if (fn === 'handleMonitor') {
        this.handleMonitor()
      }
    },
    closeOtherModal() {
      this.cameraVisible = false
      this.cameraHistoryVisible = false
      this.ctrlVisible = false
      this.viewTaskVisible = false
      this.sendTaskVisible = false
      this.manyVehicleMonitorVisible = false
    },
    handleUpdateMap() {
      const isUpdateMap =
        this.cameraVisible ||
        this.cameraHistoryVisible ||
        this.ctrlVisible ||
        this.viewTaskVisible ||
        this.sendTaskVisible ||
        this.manyVehicleMonitorVisible
      this.$emit('onUpdateMap', !isUpdateMap)
    },
    // 判断是否已选中设备
    isSelectVehicle() {
      if (!this.objectInfo) {
        if (this.currentSelectedId) {
          this.$warningEx('车辆正在连线中，请稍候。。。')
        } else {
          this.$warningEx('请先选择车辆')
        }
        return false
      } else if (!this.objectInfo.hasOwnProperty('vehicleId')) {
        this.$warningEx('车辆正在连线中，请稍候。。。')
        return false
      } else {
        return true
      }
    },
    showCameras(type) {
      console.log('showCameras showInfo:', this.objectInfo)
      if (type !== 'ctrl') {
        this.videoType = 'rtmp'
        if (this.ctrlVisible) {
          this.closeModalCtrl()
        }
      }
      this.$nextTick(() => {
        this.cameraVisible = this.isSelectVehicle()
      })
    },
    showCameraHistory() {
      console.log('showCameraHistory showInfo:', this.objectInfo)
      this.cameraHistoryVisible = this.isSelectVehicle()
    },
    handleViewTask() {
      this.viewTaskVisible = this.isSelectVehicle()
    },
    handleSendTask() {
      this.sendTaskVisible = this.isSelectVehicle()
    },
    async showModalCtrl() {
      this.ctrling = true
      if (this.isSelectVehicle()) {
        this.formScreen = false
        this.getVehicleLoginStatus()
      } else {
        this.videoType = 'rtmp'
      }
    },
    getVehicleLoginStatus() {
      InitDelayStatis()
      this.cameraVisible = false
      // *******************************
      // this.videoType = 'brtc'
      // this.ctrlVisible = true
      // if (this.videoType === 'brtc') {
      //   return
      // }
      // ********************************
      return new Promise((resolve, reject) => {
        const { timestamp, seq } = getTimestampAndSeq(this.objectInfo.vehicleId)
        AddStartRecord('login', timestamp, seq)
        vehicleLogin({
          vehicleId: this.objectInfo.bindVehicleId || this.objectInfo.vehicleId,
          seq: seq,
          timestamp: timestamp
        })
          .then(async (res) => {
            console.log('getVehicleLoginStatus **', res)
            if (res.data.result) {
              // 遥控登录seq用于登录ack校验
              this.vehicleLoginInfo = {
                loginSeq: res.data.seq,
                loginTimestamp: res.data.timestamp
              }
              if (this.objectInfo && this.objectInfo.brtc) {
                this.brtc = this.objectInfo.brtc
                this.videoType = 'brtc'
              } else if (
                this.objectInfo &&
                this.objectInfo.rtc &&
                this.objectInfo.rtc.vehicleCameraDeviceInfos.length > 0
              ) {
                await this.webReturnDeviceVehicleId()
                this.rtcInfo = this.objectInfo.rtc
                const theOrigin = window.location.origin
                if (theOrigin.indexOf('.iot.revolution.auto') > -1) {
                  this.videoType = 'rtmp'
                } else {
                  this.videoType = 'rtc'
                }
              } else {
                this.videoType = 'rtmp'
              }
              if (!this.ctrlVisible) {
                this.cameraVisible = false
              }
              this.$nextTick(() => {
                // this.showCameras('ctrl')
                this.ctrlVisible = true
              })
            } else {
              this.$warningEx('登录车辆失败，或者该车辆正在被其他人遥控中，请稍后再试！')
            }
            resolve(res.data.result)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    webReturnDeviceVehicleId() {
      return new Promise((resolve, reject) => {
        webReturnDeviceVehicleId({ vehicleId: this.objectInfo.vehicleId })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    closeModalCamera() {
      this.ctrlVisible = false
      this.cameraVisible = false
      this.ctrling = false
    },
    closeModalCameraHistory() {
      this.cameraHistoryVisible = false
    },
    closeModalCtrl() {
      this.ctrlVisible = false
      this.cameraVisible = false
      this.ctrling = false
    },
    handleCloseTaskModal() {
      this.sendTaskVisible = false
    },
    handleCloseViewTaskModal() {
      this.viewTaskVisible = false
    },
    // 处理查看任务列表的路线信息
    handleViewRoute(routeId) {
      console.log('handleViewRoute')
      const id = routeId * 1
      getLanes(id).then((res) => {
        this.$emit('onVehicleFuncEvent', 'viewRoute', res)
      })
    },
    handleMonitor() {
      console.log('handleMonitor')
      // 1.监控发现有车辆没有数据
      // 2.发起加载数据，然后利用读取到的数据加载多车监控
      if (this.objectPoints && this.objectPoints.length > 0) {
        const data = this.objectPoints.slice(0, 3)
        const ids = []
        data.forEach((p) => {
          if (!p.withAllInfo) {
            ids.push(p.vehicleId)
          }
        })
        if (ids.length === 0) {
          this.manyMonitorObjects = data
          this.manyVehicleMonitorVisible = true
        } else {
          const arr = data.map((p) => p.vehicleId)
          console.log('handleMonitor - ids', arr)
          this.$emit('onGetVehicleInfo', arr)
        }
      } else {
        if (!this.currentCheckedList) {
          this.$warningEx('请勾选需要监控的车辆！')
        } else {
          this.$warningEx('车辆正在连线中，请稍候。。。')
        }
      }
    },
    // 调用接口后主动
    setManyMonitorObjects(data) {
      if (Array.isArray(data) && data.length > 0) {
        this.manyMonitorObjects = data
        this.manyVehicleMonitorVisible = true
      } else {
        console.log('setManyMonitorObjects-获取多车监控数据为空', data)
      }
    },

    handleCloseManyVehicleMonitor() {
      this.manyVehicleMonitorVisible = false
    },
    handleVehicleMonitorEvent(eventName, eventArgs) {
      // 遥控车辆
      if (eventName === 'RemoteControlVehicle') {
        this.$emit('onVehicleFuncEvent', 'setCurrentVehicleId', eventArgs, () => {
          this.$nextTick(() => {
            this.showModalCtrl()
          })
        })
      } else {
        this.$emit('onVehicleFuncEvent', eventName, eventArgs)
      }
    }
  },
  destroyed() {
    this.timer && clearInterval(this.timer)
  }
}
</script>
<style lang="less" scoped>
.container {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-wrap: wrap;
  button {
    margin: 3px 5px;
  }
  button:first-child {
    margin-left: 0;
  }
  button:first-child {
    margin-right: 0;
  }
}
</style>
